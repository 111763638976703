import React from "react"
import styled from "styled-components"
import SubLayout from "../components/Sublayout"
import Slider from "../components/Slider"
import Img from "gatsby-image"

import { graphql } from "gatsby"
import { LocaleStoreContext } from "../components/LocaleStore"
import { FormattedMessage, FormattedDate } from "react-intl"

import Area from "../components/structural/Area"

const Article = styled.div`
  max-width: 900px;
  margin: auto;
  overflow: auto;
  background-color: white;
  margin-bottom: 32px;
  border-radius: 10px;

  h1 {
    margin: 0;
    hyphens: auto;
  }

  a {
    color: #fec110;
  }

  img {
    width: 100%;
  }

  .donate-btn {
    img {
      width: inherit;
    }
  }
`

const BlogArticleHeader = styled.div`
  position: relative;
  height: 380px;
  overflow: hidden;

  img {
    /* width: 100%; */
    /* min-height: 200px; */
    /* filter: opacity(60%); */
  }
`

const BlogArticleContent = styled.div`
  padding: 16px;
`

export default function BlogArticle({ data, pageContext }) {
  const { markdownRemark } = data
  const { frontmatter, fields } = markdownRemark
  return (
    <SubLayout>
      <Area>
        <LocaleStoreContext.Consumer>
          {({ locale }) => {
            return (
              <Article>
                {markdownRemark.sliderImgs_en ? (
                  <Slider
                    sliderImgs={markdownRemark["sliderImgs_" + locale]}
                  ></Slider>
                ) : (
                  <BlogArticleHeader>
                    <Img
                      fluid={fields.coverimg.childImageSharp.fluid}
                      imgStyle={{ minHeight: "380px" }}
                      style={{ minHeight: "380px" }}
                    />
                  </BlogArticleHeader>
                )}

                <BlogArticleContent>
                  <h1>{frontmatter["title_" + locale]}</h1>
                  <h5>
                    <FormattedDate
                      value={frontmatter.date}
                      year="numeric"
                      month="long"
                      day="2-digit"
                    ></FormattedDate>
                  </h5>

                  <h5>
                    <FormattedMessage id="blog.author" />
                    {frontmatter.author}
                  </h5>
                  {textByLocale(locale, pageContext)}
                </BlogArticleContent>
              </Article>
            )
          }}
        </LocaleStoreContext.Consumer>
      </Area>
    </SubLayout>
  )
}

function textByLocale(locale, pageContext) {
  if (locale === "de") {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: pageContext.html_de,
        }}
      ></div>
    )
  } else {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: pageContext.html_en,
        }}
      ></div>
    )
  }
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      fields {
        coverimg {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      frontmatter {
        author
        date
        path
        title_de
        title_en
      }
      sliderImgs_en {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
            presentationWidth
          }
        }
      }
      sliderImgs_de {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
            presentationWidth
          }
        }
      }
    }
  }
`
