import React, { useState, useEffect } from "react"
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons"

import Img from "gatsby-image"
import styled from "styled-components"

const SliderEl = styled.div`
  height: 550px;
  background-color: #cecece;
  position: relative;
  .MuiSvgIcon-root {
    font-size: 6rem;
    color: white;
    opacity: 0.7;

    background-color: #585858;
    border-radius: 100%;
    height: 50px;
    width: 50px;

    :hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  img {
    object-fit: contain !important;
  }
`

const Left = styled.div`
  position: absolute;
  z-index: 1;
  top: 250px;
  left: 8px;
`
const Right = styled.div`
  position: absolute;
  z-index: 1;
  top: 250px;
  right: 8px;
`

function slideRight(i, setImgIndex, len) {
  if (i === len - 1) return
  setImgIndex(i + 1)
}

function slideLeft(i, setImgIndex) {
  if (i <= 0) return
  setImgIndex(i - 1)
}

function Slider({ sliderImgs }) {
  const [imgIndex, setImgIndex] = useState(0)
  const [width, setWidth] = useState(
    typeof window !== `undefined` ? window.innerWidth : 0
  )

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      //   handleSliderResponsivness(width, imgIndex, setImgIndex)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [imgIndex, width])
  return (
    <SliderEl>
      <Left>
        <KeyboardArrowLeft
          onClick={() => slideLeft(imgIndex, setImgIndex, sliderImgs.length)}
        ></KeyboardArrowLeft>
      </Left>
      <Img fluid={sliderImgs[imgIndex].childImageSharp.fluid} />
      <Right>
        <KeyboardArrowRight
          onClick={() => slideRight(imgIndex, setImgIndex, sliderImgs.length)}
        ></KeyboardArrowRight>
      </Right>
    </SliderEl>
  )
}

export default Slider
